import { Body, Children, Footer, GitLabSvg, GithubSvg, Header, LinkedInSvg, Theme, TwitterXSvg, YoutubeSvg } from '@testifysec/wings';

import React, { useEffect } from 'react';

import { graphql, Link, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import './DefaultLayout.css';

// Extend the global Window interface for TypeScript
declare global {
  interface Window {
    ldfdr?: (...args: any[]) => void;
  }
}

export interface DefaultLayoutProps {
  children?: Children;
  props?: any;
}

export const DefaultLayout: React.FC<DefaultLayoutProps> = ({ props, children }) => {
  const location = useLocation();

  const data = useStaticQuery(graphql`
    query DefaultLayout {
      allPayloadHeader {
        nodes {
          logo {
            alt
            url
          }

          nav {
            id
            label
            link
            children {
              id
              link
              label
              newTab
            }
          }
        }
      }
      allPayloadFooter {
        nodes {
          id
          logo {
            id
            alt
            url
          }
          backgroundImage {
            alt
            url
          }
          logoText
          contactPhone
          copyrightNotice
          socialLinks {
            id
            icon
            link
            newTab
          }
          nav {
            id
            link
            label
          }
        }
      }
    }
  `);

  const navLinksHeader = data.allPayloadHeader.nodes[0].nav;

  const PrimaryNavItems = navLinksHeader.map((navItem: any) => {
    if (navItem.children.length > 0) {
      return {
        label: navItem.label,
        href: navItem.link,
        navItems: navItem.children.map((child: any) => ({
          label: child.label,
          href: child.link,
        })),
      };
    } else {
      return {
        label: navItem.label,
        href: navItem.link,
      };
    }
  });
  const navLinksFooter = data.allPayloadFooter.nodes[0].nav;
  const logoTextFooter = data.allPayloadFooter.nodes[0].logoText;
  const contactPhoneFooter = data.allPayloadFooter.nodes[0].contactPhone;

  const backgroundImageFooter = data.allPayloadFooter.nodes[0].backgroundImage;
  const copyrightNotice = data.allPayloadFooter.nodes[0].copyrightNotice;

  const socialLinksFooter = data.allPayloadFooter.nodes[0].socialLinks;

  const socialLinksInCorrectShape = socialLinksFooter.map((socialLink: any) => {
    let icon: any = null;

    switch (socialLink.icon) {
      case 'X':
        icon = TwitterXSvg;
        break;
      case 'gitHub':
        icon = GithubSvg;
        break;
      case 'gitLab':
        icon = GitLabSvg;
        break;
      case 'youTube':
        icon = YoutubeSvg;
        break;
      case 'linkedIn':
        icon = LinkedInSvg;
        break;
      default:
        icon = null;
        break;
    }

    return {
      href: socialLink.link,
      name: socialLink.icon,
      icon: icon,
    };
  });

  const FooterNavItems = navLinksFooter.map((navItem: any) => {
    return {
      label: navItem.label,
      href: navItem.link,
    };
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Initialize window.ldfdr and its _q property if they don't exist
      if (!window.ldfdr) {
        window.ldfdr = ((...args: any[]) => {
          //eslint-disable-next-line
          (window?.ldfdr as any)?._q?.push(args);
        }) as any;
        //eslint-disable-next-line
        (window?.ldfdr as any)._q = [];
      }

      const scriptId = 'ldfdr-tracker';
      if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = 'https://sc.lfeeder.com/lftracker_v1_lAxoEaK9pro7OYGd.js';
        script.async = true;
        document.body.appendChild(script);
      }
    }
  }, [location.pathname]); // Assuming location is defined. If not, you may need to import or define it.

  // const body = <Body className={`${isBlog || isDocs ? 'blog-post container-x container-y md:max-w-2xl' : ''}`}>{children}</Body>;
  const body = <Body className={''}>{children}</Body>;

  return (
    <Theme {...props}>
      <Header
        // logo={React.createElement("img", { src: logoHeader.url, alt: logoHeader.alt })}
        navItems={PrimaryNavItems}
        actionButtonHref="https://calendly.com/testifysec-team/judge-demo-and-q-a-for-government-contractors-clone"
        actionButtonLabel="Let's Talk"
        componentType={Link} // Removed the `as any` casting
      />

      <div className="overflow-x-hidden">{body}</div>
      <Footer
        bgImage={backgroundImageFooter.url}
        copyrightOwner={copyrightNotice}
        footerBlurb={logoTextFooter}
        phoneNumber={contactPhoneFooter}
        navItems={FooterNavItems}
        socials={socialLinksInCorrectShape}
      />
    </Theme>
  );
};
