import '@testifysec/wings/dist/wings.css';

import { DefaultLayout } from './src/layouts/DefaultLayout';
import React from 'react';

/**
 * Gatsby Browser API
 * Wraps the page element with the Theme, Header, and Footer components
 * Use the Gatsby Browser API to control the rendering of the page client side
 * @param param0
 * @returns
 */
export const wrapPageElement = ({ element, props }: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return <DefaultLayout {...props}>{element}</DefaultLayout>;
};
